import { KeyVaultApiService } from '@core/services/api.services/key-vault-api.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { MemberProfileApiService } from '@core/services/api.services/member-profile-api.service';
import {
  MemberProfileDto,
  MemberSyncDto,
  PaginatedResponseDto,
  ProductDto,
  ProductTypeDto,
} from '@core/services/dto';
import { filter, map, switchMap, finalize, tap, take } from 'rxjs/operators';
import {
  AccountsApiService,
  OfferApiService,
  ProductApiService,
  ConversationApiService,
  EmailTemplatesApiService,
} from '@core/services/api.services';
import { CampaignsDto } from '@core/services/dto/campaigns.dto';
import { SpinnerService } from '@shared/services/spinner.service';
import {
  CampaignsModel,
  MemberProfileModel,
  MemberSyncModel,
  ProductModel,
  ProductTypeModel,
  SearchAccountOptionsModel,
  UserNameModel,
  SiteDetailModel,
  SearchUserOptionsModel,
  SendEmailRequestModel,
} from '@shared/models';
import { LinkSearchRequestDto } from '@shared/models/requests/link-search.request.dto';
import { BankIntegrationConfigurationDto } from '@shared/models/bank-integration-configuration.dto';
import { ApplicationsApiService } from '@core/services/api.services/applications-api.service';
import { UserBranchDto } from './dto/user-Branch.dto';
import { UserBranchModel } from '@shared/models/user-Branch.model';
import { ProspectProfileApiService } from './api.services/prospect-profile-api.service';
import { ProspectCreate } from '@shared/models/prospect-create';
import { HttpErrorResponse } from '@angular/common/http';
import { getOfferApiBase } from '@config/org.config';
import { ConcernTypeModel } from '@shared/models/concern-type.models';
import { ConcernTypeDto } from './dto/concern-type.dto';
import { SendEmailRequestDto } from '@shared/models/requests/send.email.request.dto';

const offerBase = getOfferApiBase();

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  private productTypes: BehaviorSubject<ProductTypeModel[]> =
    new BehaviorSubject<ProductTypeModel[]>([]);
  private concernTypes: BehaviorSubject<ConcernTypeModel[]> =
    new BehaviorSubject<ConcernTypeModel[]>([]);
  private concernType: BehaviorSubject<ConcernTypeModel[]> =
    new BehaviorSubject<ConcernTypeModel[]>([]);
  private campaigns: BehaviorSubject<CampaignsModel[]> = new BehaviorSubject<
    CampaignsModel[]
  >([]);
  private userBranches: BehaviorSubject<UserBranchModel[]> = new BehaviorSubject<
    UserBranchModel[]
  >([]);
  public products: BehaviorSubject<ProductModel[]> = new BehaviorSubject<
    ProductModel[]
  >([]);
  private bankIntegrationConfigurationSubject: BehaviorSubject<BankIntegrationConfigurationDto> =
    new BehaviorSubject(null);
  private sqlBuilderPageVisibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(null);
  private ECMUsageKeySubject: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private PendoUsageKeySubject: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  private themeBuilderTypeKeySubject: BehaviorSubject<string> =
    new BehaviorSubject(null);

  private ECMUsageKeyLoading: boolean;
  private PendoUsageKeyLoading: string;
  private sqlBuilderUsageKeyLoading: boolean;
  private themeBuilderTypeKeyLoading: boolean;

  public noteUpdated: Subject<boolean> = new Subject<boolean>();
  public noteUpdated$: Observable<boolean> = this.noteUpdated.asObservable();
  public saleAdded: Subject<boolean> = new Subject<boolean>();
  public saleAdded$: Observable<boolean> = this.saleAdded.asObservable();
  public taskUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public interactionUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public conversationUpdated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public memberProfile: BehaviorSubject<MemberProfileModel> =
    new BehaviorSubject<MemberProfileModel>(null);
  public prospectProfile: BehaviorSubject<ProspectCreate> =
    new BehaviorSubject<ProspectCreate>(null);
  public preselectedAccount: BehaviorSubject<SearchAccountOptionsModel[]> =
    new BehaviorSubject<SearchAccountOptionsModel[]>(null);
  public preselectedAccount$: Observable<SearchAccountOptionsModel[]> =
    this.preselectedAccount.asObservable();
  public user: UserNameModel;
  public userGroup: SearchUserOptionsModel;

  public userEmail: string;
  private importResponse: BehaviorSubject<MemberSyncModel> =
    new BehaviorSubject<MemberSyncModel>(null);
  private UmbrellaVersionSubject: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  private UmbrellaVersionLoding: string;
  public siteDetail: BehaviorSubject<SiteDetailModel> =
    new BehaviorSubject<SiteDetailModel>(null);

  public offerSiteDetail: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  public offerEnableLogic: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);

  public isOfferLicence: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  isProgress = false;

  public CUName = new BehaviorSubject('');
  public refreshBatchAssigneeTask = new BehaviorSubject(null);
  public refreshBatchAssigneeTask$ = this.refreshBatchAssigneeTask.asObservable().pipe(filter((x) => !!x));

  public importResponse$: Observable<MemberSyncModel> = this.importResponse
    .asObservable()
    .pipe(filter((x) => !!x));

  public bankIntegrationConfiguration$ =
    this.bankIntegrationConfigurationSubject.asObservable().pipe(
      tap((config) => {
        if (!config) {
          this.loadBankIntegrationConfiguration();
        }
      }),
      filter((config) => !!config)
    );

  public sqlBuilderPageVisible$ = this.sqlBuilderPageVisibleSubject
    .asObservable()
    .pipe(
      tap((visible) => {
        if (
          !this.sqlBuilderUsageKeyLoading &&
          (visible === undefined || visible === null)
        ) {
          this.loadSqlBuilderUsageKey();
        }
      }),
      filter((visible) => !(visible === undefined || visible === null))
    );

  public offerVisible$ = this.offerSiteDetail.asObservable().pipe(
    tap((visible) => {
      if (visible === undefined || visible === null) {
        this.getOfferSite();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );

  public offerLicence$ = this.isOfferLicence.asObservable().pipe(
    tap((visible) => {
      if (visible === undefined || visible === null) {
        this.getOfferLicence();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );

  public offerEnableLogic$ = this.offerEnableLogic.asObservable().pipe(
    tap((visible) => {
      if (visible === undefined || visible === null) {
        this.getOfferEnableLogic();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );

  public ECMVisible$ = this.ECMUsageKeySubject.asObservable().pipe(
    tap((visible) => {
      if (
        !this.ECMUsageKeyLoading &&
        (visible === undefined || visible === null)
      ) {
        this.loadECMUsageKey();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );

  public themeBuilderType$ = this.themeBuilderTypeKeySubject
    .asObservable()
    .pipe(
      tap((type) => {
        if (
          !this.themeBuilderTypeKeyLoading &&
          (type === undefined || type === null)
        ) {
          this.loadThemeBuilderKey();
        }
      }),
      filter((type) => !(type === undefined || type === null))
    );

  public VersionVisible$ = this.UmbrellaVersionSubject.asObservable().pipe(
    tap((visible) => {
      if (
        !this.UmbrellaVersionLoding &&
        (visible === undefined || visible === null)
      ) {
        this.loadUmbrellaVersion();
        this.getOfferSite();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );

  public PendoAPIKey$ = this.PendoUsageKeySubject.asObservable().pipe(
    tap((visible) => {
      if (
        !this.PendoUsageKeyLoading &&
        (visible === undefined || visible === null)
      ) {
        this.loadPendoUsageKey();
      }
    }),
    filter((visible) => !(visible === undefined || visible === null))
  );
  constructor(
    private memberProfileApiService: MemberProfileApiService,
    private prospectProfileApiService: ProspectProfileApiService,
    private productService: ProductApiService,
    private conversationService: ConversationApiService,
    private accountsService: AccountsApiService,
    private spinnerService: SpinnerService,
    private toastService: CustomSnackbarService,
    private keyVaultService: KeyVaultApiService,
    private asappApiService: ApplicationsApiService,
    private offerApiService: OfferApiService,
    private emailsService: EmailTemplatesApiService
  ) { }

  memberProfile$(): Observable<MemberProfileModel> {
    return this.memberProfile.asObservable().pipe(filter((x) => !!x));
  }

  productTypes$(): Observable<ProductTypeModel[]> {
    return this.productTypes.asObservable();
  }

  products$(): Observable<ProductModel[]> {
    return this.products.asObservable();
  }

  campaigns$(): Observable<CampaignsModel[]> {
    return this.campaigns.asObservable();
  }

  userBranches$(): Observable<UserBranchModel[]> {
    return this.userBranches.asObservable();
  }

  taskUpdated$(): Observable<boolean> {
    return this.taskUpdated.asObservable();
  }

  interactionUpdated$(): Observable<boolean> {
    return this.interactionUpdated.asObservable();
  }

  conversationUpdated$(): Observable<boolean> {
    return this.conversationUpdated.asObservable();
  }

  concernTypes$(): Observable<ConcernTypeModel[]> {
    return this.concernTypes.asObservable();
  }
  concernType$(): Observable<ConcernTypeModel[]> {
    return this.concernType.asObservable();
  }

  getMemberProfile(memberId: number): void {
    this.memberProfileApiService
      .getMemberProfileInfo(memberId)
      .subscribe((data: MemberProfileDto) => {
        this.memberProfile.next(new MemberProfileModel(data));
      });
  }

  getProspectProfile(memberId: number): void {
    this.prospectProfileApiService
      .getProspectProfileInfo(memberId)
      .subscribe((data: MemberProfileDto) => {
        this.memberProfile.next(new MemberProfileModel(data));
      });
  }

  review(memberId: number): void {
    this.memberProfileApiService.review(memberId).subscribe((memberInfo) => {
      this.memberProfile.next(new MemberProfileModel(memberInfo));
    });
  }

  status(prospectId: number, prospectStatus: string): void {
    this.prospectProfileApiService.status(prospectId, prospectStatus).subscribe((memberInfo) => {
      this.memberProfile.next(new MemberProfileModel(memberInfo));
    });
  }

  clearMember(): void {
    this.memberProfile.next(null);
    this.importResponse.next(null);
  }

  clearBankIntegrationConfiguration(): void {
    this.bankIntegrationConfigurationSubject.next(null);
  }

  clearAccount(): void {
    this.preselectedAccount.next(null);
  }

  updateConversations(): void {
    this.conversationUpdated.next(true);
  }

  updateTasks(): void {
    this.taskUpdated.next(true);
  }

  updateInteractions(): void {
    this.interactionUpdated.next(true);
  }

  poll(
    memberNumber: string,
    branch: number,
    memberType: string,
  ): Observable<MemberSyncDto> {
    return new Observable<MemberSyncDto>((observer) => {
      const subscription = interval(2500)
        .pipe(
          switchMap(() => this.checkImport(memberNumber, branch, memberType)),
          filter(
            (response: MemberSyncDto) =>
              !response ||
              response.status === 'Success' ||
              response.status === 'Failed'
          )
        )
        .subscribe((response) => {
          observer.next(response);
          observer.complete();
          // cancel the interval
          subscription.unsubscribe();
        });
    });
  }

  prospectPoll(
    memberNumber: string,
    branch: number,
    memberType: string,
  ): Observable<MemberSyncDto> {
    return new Observable<MemberSyncDto>((observer) => {
      const subscription = interval(2500)
        .pipe(
          switchMap(() => this.checkImportProspect(memberNumber, branch, memberType)),
          filter(
            (response: MemberSyncDto) =>
              !response ||
              response.status === 'Success' ||
              response.status === 'Failed'
          )
        )
        .subscribe((response) => {
          observer.next(response);
          observer.complete();
          // cancel the interval
          subscription.unsubscribe();
        });
    });
  }


  importMemberById(memberId: number): void {
    this.spinnerService.showPermanent();

    this.memberProfileApiService
      .importMemberById(memberId)
      .subscribe((data) => {
        this.poll(data.memberNumber, data.branchId, data.memberType)
          .pipe(
            map((response) => new MemberSyncModel(response)),
            finalize(() => {
              this.spinnerService.hidePermanent();
            })
          )
          .subscribe((response) => {
            response.memberId = memberId;
            this.importResponse.next(response);

            if (response.status === 'Failed') {
              this.toastService.fail(response.message);
            }
          });
      });
  }

  importProspectById(memberId: number): void {
    this.spinnerService.showPermanent();

    this.prospectProfileApiService
      .importProspectById(memberId)
      .subscribe((data) => {
        this.prospectPoll(data.memberNumber, data.branchId, data.memberType)
          .pipe(
            map((response) => new MemberSyncModel(response)),
            finalize(() => {
              this.spinnerService.hidePermanent();
            })
          )
          .subscribe((response) => {
            response.memberId = memberId;
            this.importResponse.next(response);

            if (response.status === 'Failed') {
              this.toastService.fail(response.message);
            }
          });
      });
  }

  import(
    memberNumber: string,
    branch: number,
    memberType: string,
    showPermanentSpinner: boolean = false,
    showToastOnError: boolean = false
  ): void {
    showPermanentSpinner
      ? this.spinnerService.showPermanent()
      : this.spinnerService.show();

    this.memberProfileApiService
      .importMember(memberNumber, branch, memberType)
      .subscribe((data) => {
        if (!this.isProgress && data.status === 'InProgress') {
          this.toastService.success('Member import is in progress');
          this.isProgress = true;
        }
        this.poll(memberNumber, branch, memberType)
          .pipe(
            map((response) => new MemberSyncModel(response)),
            finalize(() => {
              showPermanentSpinner
                ? this.spinnerService.hidePermanent()
                : this.spinnerService.hide();
            })
          )
          .subscribe((response) => {
            response.memberNumber = memberNumber;
            response.memberBranch = branch;
            response.memberType = memberType;
            this.importResponse.next(response);

            if (!showToastOnError && response.status === 'Success') {
              this.toastService.success('Successfully imported');
              this.isProgress = false;
            }
            else if (showToastOnError && response.status === 'Failed') {
              this.toastService.success('Error while importing');
              this.isProgress = false;
            }
          });
      });
  }

  checkImport(
    memberNumber: string,
    branch: number,
    memberType: string
  ): Observable<MemberSyncModel> {
    return this.memberProfileApiService.checkImport(
      memberNumber,
      branch,
      memberType
    );
  }

  checkImportProspect(
    memberNumber: string,
    branch: number,
    memberType: string
  ): Observable<MemberSyncModel> {
    return this.prospectProfileApiService.checkImportProspect(
      memberNumber,
      branch,
      memberType
    );
  }

  getMemberAccount(request: LinkSearchRequestDto): void {
    this.accountsService
      .getAccounts(request)
      .pipe()
      .subscribe((res) => {
        const AccountOptions = [];
        res.data.forEach((item) => {
          const account = new SearchAccountOptionsModel(item);
          AccountOptions.push(account);
        });
        this.preselectedAccount.next(AccountOptions);
      });
  }

  getMemberAccountData(request: LinkSearchRequestDto): Observable<any> {
    return this.accountsService.getAccounts(request).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  getProductTypes(): void {
    this.productService
      .getProductType()
      .pipe(
        map((response: PaginatedResponseDto<ProductTypeDto>) =>
          response.data.map((item) => new ProductTypeModel(item))
        )
      )
      .subscribe((data) => {
        this.productTypes.next(data);
      });
  }

  getProducts(productTypeId?: number): void {
    this.productService
      .getProducts(productTypeId)
      .pipe(
        map((response: PaginatedResponseDto<ProductDto>) =>
          response.data.map((item) => new ProductModel(item))
        )
      )
      .subscribe((data) => {
        this.products.next(data);
      });
  }

  getConcernType(concernTypeId?: number): void {
    this.conversationService
      .getConcernType(concernTypeId)
      .pipe(
        map((response: PaginatedResponseDto<ConcernTypeDto>) =>
          response.data.map((item) => new ConcernTypeModel(item))
        )
      )
      .subscribe((data) => {
        this.concernType.next(data);
      });
  }
  getConcernTypes(): void {
    this.conversationService
      .getConcernTypes()
      .pipe(
        map((response: PaginatedResponseDto<ConcernTypeDto>) =>
          response.data.map((item) => new ConcernTypeModel(item))
        )
      )
      .subscribe((data) => {
        this.concernTypes.next(data);
      });
  }

  getCampaigns(active: boolean = true): void {
    const request = {
      status: !!active ? 'active' : active === false ? 'inactive' : null,
    };

    this.productService
      .getCampaigns(request)
      .pipe(
        map((response: PaginatedResponseDto<CampaignsDto>) =>
          response.data.map((item) => new CampaignsModel(item))
        )
      )
      .subscribe((data) => {
        this.campaigns.next(data);
      });
  }

  getBranch(active: boolean = true): void {
    this.asappApiService
      .getUserBranch()
      .pipe(
        map((response: UserBranchDto[]) =>
          response.map((item) => new UserBranchModel(item))
        )
      )
      .subscribe((data) => {
        this.userBranches.next(data);
      });
  }

  loadBankIntegrationConfiguration(): void {
    this.memberProfileApiService
      .getBankIntegrationConfiguration()
      .subscribe((config) => {
        this.bankIntegrationConfigurationSubject.next(config);
      });
  }

  loadECMUsageKey(): void {
    this.offerApiService.getOfferSiteDetails();
    this.ECMUsageKeyLoading = true;

    this.keyVaultService
      .getECMUsageKey()
      .pipe(finalize(() => (this.ECMUsageKeyLoading = false)))
      .subscribe((ECMVisible) => this.ECMUsageKeySubject.next(ECMVisible));
  }

  loadSqlBuilderUsageKey(): void {
    this.sqlBuilderUsageKeyLoading = true;

    this.keyVaultService
      .getSqlBuilderUsageKey()
      .pipe(finalize(() => (this.sqlBuilderUsageKeyLoading = false)))
      .subscribe((sqlBuilderPageVisible) =>
        this.sqlBuilderPageVisibleSubject.next(sqlBuilderPageVisible)
      );
  }

  loadThemeBuilderKey(): void {
    this.themeBuilderTypeKeyLoading = true;

    this.keyVaultService
      .getThemeBuilderTypeKey()
      .pipe(finalize(() => (this.themeBuilderTypeKeyLoading = false)))
      .subscribe((themeBuilderType) =>
        this.themeBuilderTypeKeySubject.next(themeBuilderType)
      );
  }

  loadUmbrellaVersion(): void {
    // this.UmbrellaVersionSubject.next(this.keyVaultService.getUmbrellaVersion())
    this.keyVaultService
      .getUmbrellaVersion()
      .pipe(finalize(() => (this.UmbrellaVersionLoding = null)))
      .subscribe((VersionVisible) =>
        this.UmbrellaVersionSubject.next(VersionVisible)
      );
  }

  loadPendoUsageKey(): void {
    this.keyVaultService
      .getPendoApiKey()
      .pipe(finalize(() => (this.PendoUsageKeyLoading = null)))
      .subscribe((PendoKey) =>
        this.PendoUsageKeySubject.next(PendoKey)
      );
  }

  getSiteDetails(): any {
    return this.asappApiService.getSiteDetails();
  }

  getOfferSite(): void {
    if (offerBase !== undefined) {
      this.offerApiService.getOfferSiteDetails().subscribe((data) => {
        if (data.isEnableOffer && !data.isEnableAOSLOS && !data.isEnableCRM) {
          this.offerSiteDetail.next(false);
        } else if (
          data.isEnableOffer &&
          data.isEnableAOSLOS &&
          !data.isEnableCRM
        ) {
          this.offerSiteDetail.next(true);
        } else {
          this.offerSiteDetail.next(true);
        }
      });
    } else {
      this.offerSiteDetail.next(true);
    }
  }

  getOfferEnableLogic(): void {
    if (offerBase !== undefined) {
      this.offerApiService.getOfferSiteDetails().subscribe(
        (data) => {
          if (data.isEnableOffer && !data.isEnableAOSLOS && !data.isEnableCRM) {
            this.offerEnableLogic.next(1);
          } else if (
            data.isEnableOffer &&
            data.isEnableAOSLOS &&
            !data.isEnableCRM
          ) {
            this.offerEnableLogic.next(2);
          } else if (
            !data.isEnableOffer &&
            data.isEnableAOSLOS &&
            data.isEnableCRM
          ) {
            this.offerEnableLogic.next(3);
          } else {
            this.offerEnableLogic.next(0);
          }
        },
        (error: HttpErrorResponse) => {
          this.offerEnableLogic.next(3);
        }
      );
    } else {
      this.offerEnableLogic.next(3);
    }
  }

  getOfferLicence(): void {
    if (offerBase !== undefined) {
      this.offerApiService.getOfferSiteDetails().subscribe(
        (data) => {
          this.isOfferLicence.next(data.isEnableOffer);
        },
        (error: HttpErrorResponse) => {
          this.isOfferLicence.next(false);
        }
      );
    } else {
      this.isOfferLicence.next(false);
    }
  }

  sendBulkEmails(CRMType, emailAddresses, userGroup): void {
    const emailsBulk = [];
    emailAddresses.forEach(data => {
      const sendRequest = new SendEmailRequestModel();
      sendRequest.from.address = this.userEmail;
      sendRequest.from.name = `${this.user.firstName} ${this.user.lastName}`;
      sendRequest.subject = 'New Assignment';
      sendRequest.body = 'A new or existing ' + CRMType + ' has been assigned to ' + userGroup + ' Group. Please sign into the Teams portal to review.';
      sendRequest.data = null;
      sendRequest.to.address = data;
      emailsBulk.push(sendRequest);
    });
    this.sendBulk(emailsBulk).subscribe((message) => {
      if (message) { }
    });
  }

  sendBulk(request): Observable<SendEmailRequestDto> {
    return this.emailsService.sendBulk(request);
  }

  send(request: SendEmailRequestDto): Observable<SendEmailRequestDto> {
    return this.emailsService.send(request);
  }

  SendEmail(request, CRMType): void {
    const sendRequest = new SendEmailRequestModel();
    sendRequest.from.address = this.userEmail;
    sendRequest.from.name = `${this.user.firstName} ${this.user.lastName}`;
    sendRequest.subject = 'New Assignment';
    sendRequest.body = 'A new or existing ' + CRMType + ' has been assigned to you. Please sign into the Teams portal to review.';
    sendRequest.data = null;
    sendRequest.to.address = request.to.address;
    this.send(sendRequest).subscribe((message) => {
      if (message) { }
    });
  }

  sendCRMImportBulkEmailUsers(CRMType, emailAddresses, importId): void {
    const emailsBulk = [];
    emailAddresses.forEach(data => {
      const sendRequest = new SendEmailRequestModel();
      sendRequest.from.address = this.userEmail;
      sendRequest.from.name = `${this.user.firstName} ${this.user.lastName}`;
      sendRequest.subject = 'New Assignment';
      sendRequest.body = `One or more ${CRMType}s have been assigned to you via Import ${importId}. Please sign into the Teams portal to review.`;
      sendRequest.data = null;
      sendRequest.to.address = data;
      emailsBulk.push(sendRequest);
    });
    this.sendBulk(emailsBulk).subscribe((message) => {
      if (message) { }
    });
  }

  sendCRMImportBulkEmailGroups(emailsBulk): void {
    emailsBulk.forEach(data => {
      data.from.address = this.userEmail;
      data.from.name = `${this.user.firstName} ${this.user.lastName}`;
    });
    this.sendBulk(emailsBulk).subscribe((message) => {
      if (message) { }
    });
  }
}
