import { EcmFoldersDto } from '@core/services/dto';
import { DEFAULT_DATE_FORMAT, formatDateToLocalSimple } from '@shared/utils';
import { LinkDto } from '@core/services/dto/link.dto';

export class EcmFolderModel {
  id: number;
  name: string;
  isFile: boolean;
  lastUpdated: string;
  isDisabled: boolean;
  required: boolean;
  member: LinkDto[];
  fileSubType: string;
  fileType: string;
  memberNumber: string[] = [];
  isLinkHidden = false;
  isAdmin = false;

    constructor(dto: EcmFoldersDto, isAdmin: boolean) {
      for (const prop in dto) {
        if (dto.hasOwnProperty(prop)) {
          this[prop] = dto[prop];
        }
      }
      this.lastUpdated = dto.lastUpdated ? formatDateToLocalSimple(dto.lastUpdated, DEFAULT_DATE_FORMAT) : dto.lastUpdated;
      this.isDisabled =  !dto.hasAccess;
      this.isAdmin = isAdmin;
      this.member = dto.links;
      this.fileSubType = dto.subType?.name;
      this.fileType = dto.type?.name;
      this.isLinkHidden = !this.isFile;
  }
}
