import { Injectable } from '@angular/core';
import { MemberProfileApiService } from '@core/services/api.services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LinkSearchRequestDto } from '@shared/models/requests/link-search.request.dto';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import {
  SearchMemberOptionsModel,
  SearchMemberModel
} from '@shared/models';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';

@Injectable({providedIn: 'any'})

export class MainHeaderStateService extends autoUnsubscribeMixin() {

  public members: BehaviorSubject<SearchMemberProspectOptionsModel[]> = new BehaviorSubject<SearchMemberProspectOptionsModel[]>([]);

  constructor(
    private memberProfileService: MemberProfileApiService
  ) {
    super();
  }

  members$(): Observable<SearchMemberProspectOptionsModel[]> {
    return this.members.asObservable();
  }

  getSearchedMembers(request: LinkSearchRequestDto): void {
    this.memberProfileService.searchMember(request).pipe(
      map((response => new SearchMemberModel(response)))
    ).subscribe(
      res => {
        const memberOptions = [];
        res.data.forEach(item => {
          const member = new SearchMemberProspectOptionsModel(item);
          memberOptions.push(member);
        });
        this.members.next(memberOptions);
      }
    );
  }
}
