import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MainHeaderComponent } from './main-header.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderActionItemModule } from '@shared/components/header-action-item';
import { ThreeDotsMenuModule } from '@shared/components/three-dots-menu/three-dots-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationDialogModule } from '@shared/components/confirmation-dialog/confirmation-dialog.module';
import { InputAutocompleteModule } from '@shared/components/input-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    MainHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    HeaderActionItemModule,
    ThreeDotsMenuModule,
    MatMenuModule,
    SharedModule,
    ConfirmationDialogModule,
    InputAutocompleteModule,
    ReactiveFormsModule
  ],
  exports: [
    MainHeaderComponent
  ],
})
export class MainHeaderModule {
}
