import { SaleCreateInterface } from '../../models/sale-create.interface';
import { CreateSalesApiRequest } from '../../models/requests/crete-sales.request.dto';
import { GlobalStateService } from '@core/services/global.state.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConversationApiService } from '@core/services/api.services/conversation-api.service';
import { ConversationModel } from '@shared/models/conversation.model';
import { InteractionModel } from '@shared/models/interaction.model';
import { InteractionApiService } from '@core/services/api.services/interaction-api.service';
import {
  ConversationWorkflowModel,
  SearchUserOptionsModel,
  Task,
  WorkflowModel,
  SearchAccountOptionsModel,
  TaskCreateInterface,
  OpportunityCreateModel,
  ConcernCreateModel,
  InteractionCreateInterface,
  ConversationCreateModel
} from '@shared/models';
import { ProductApiService, TaskApiService, SalesApiService } from '@core/services/api.services';
import { EmailTemplateDto, PaginatedResponseDto, TaskTemplateDto, UserDto, WorkflowTemplateDto } from '@core/services/dto';
import { WorkflowApiService } from '@core/services/api.services/workflow-api.service';
import { WorkflowTemplateRequest } from '@shared/models/requests/workflow-template.request.dto';
import { UserApiService } from '@core/services/api.services/user-api.service';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { filter, map } from 'rxjs/operators';
import { TaskTemplateRequest } from '@shared/models/requests/task-template.request.dto';
import { TaskTemplateStatus } from '@shared/models/task-template-status.model';
import { TaskTemplateUseTypeEnum } from '@shared/models/task-template-use-type.model';
import { UserStatusTypeStringEnum } from '@shared/enums/user-status-type.enum';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Injectable({ providedIn: 'any' })

export class CreateConversationStateService {
  private taskTemplates: BehaviorSubject<TaskTemplateDto[]> = new BehaviorSubject([]);
  private emailTemplates: BehaviorSubject<PaginatedResponseDto<EmailTemplateDto>> =
    new BehaviorSubject<PaginatedResponseDto<EmailTemplateDto>>(null);

  public taskUsers: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);
  public conversationUsers: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);
  public taskTemplates$: Observable<TaskTemplateDto[]> = this.taskTemplates.asObservable();
  public emailTemplates$: Observable<PaginatedResponseDto<EmailTemplateDto>> = this.emailTemplates
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  taskUsers$(): Observable<SearchUserOptionsModel[]> {
    return this.taskUsers.asObservable();
  }

  conversationUsers$(): Observable<SearchUserOptionsModel[]> {
    return this.conversationUsers.asObservable();
  }

  constructor(
    private service: ConversationApiService,
    private globalState: GlobalStateService,
    private interactionService: InteractionApiService,
    private taskService: TaskApiService,
    private workflowService: WorkflowApiService,
    private userService: UserApiService,
    private saleService: SalesApiService
  ) {

  }

  member$(): Observable<any> {
    return this.globalState.memberProfile;
  }

  preselectedAccount$(): Observable<SearchAccountOptionsModel[]> {
    return this.globalState.preselectedAccount;
  }

  getUsers(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: 3, search: searchString };
    return this.userService.getUsers(request);
  }

  getUserGroups(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: null, search: searchString };
    return this.userService.getUserGroups(request);
  }

  getSearchedUsers(search: string, isConversation: boolean): void {
    this.getUsers(search).pipe(

      map((response) => {

        return {
          ...response,
          data: response.data.map(item => new SearchUserOptionsModel(item))
        };
      })
    ).subscribe(response => {
      isConversation ? this.conversationUsers.next(response.data) : this.taskUsers.next(response.data);
    });

  }

  getSearchedUserGroups(search: string, isConversation: boolean): void {
    this.getUserGroups(search).pipe(

      map((response) => {
        const filteredData = response.data.filter(item => {
          // Filter out items with type 'Group' and status 'Inactive'
          if (item.isGroup === true && item.status === 'Inactive') {
            return false;
          }
          // Include items with types 'CRM Assignment', 'User', or 'All' And Status Type 'New' or 'Active'
          return (item.type === GroupTypeStringEnum.CRMAssignment ||
            item.type === 'User' ||
            item.type === GroupTypeStringEnum.All ||
            item.type === GroupTypeStringEnum.CRMandRepresentativeRole ||
            item.type === GroupTypeStringEnum.RepresentativeRole) &&
            (item.status === UserStatusTypeStringEnum.New ||
            item.status === UserStatusTypeStringEnum.Active);
        });
        return {
          ...response,
          data: filteredData.map(item => new SearchUserOptionsModel(item))
        };
      })
    ).subscribe(response => {
      isConversation ? this.conversationUsers.next(response.data) : this.taskUsers.next(response.data);
    });

  }

  setConversation(conversation: ConversationCreateModel): Observable<ConversationModel> {
    return this.service.setConversation(conversation);
  }

  setConcern(concern: ConcernCreateModel): Observable<ConversationModel> {
    return this.service.setConcern(concern);
  }

  setOpportunity(opportunity: OpportunityCreateModel): Observable<ConversationModel> {
    return this.service.setOpportunity(opportunity);
  }

  setInteraction(interaction: InteractionCreateInterface): Observable<InteractionModel> {
    return this.interactionService.setInteraction(interaction);
  }

  setTask(task: TaskCreateInterface): Observable<any> {
    return this.taskService.createTask(task);
  }

  setSale(sale: SaleCreateInterface): Observable<any> {
    const request: CreateSalesApiRequest = {
      applicationId: sale.applicationId,
      campaignId: sale.campaignId,
      branchId: sale.branchId,
      referrers: sale.referrers.map(r => r.ref),
      links: sale.links.map(l => ({
        ref: l.ref,
        type: l.type
      })),
      products: sale.products
    };

    return this.saleService.createSale(request);
  }

  getWorkflowTemplates(request: WorkflowTemplateRequest): Observable<PaginatedResponseDto<WorkflowTemplateDto>> {
    return this.workflowService.getWorkflowTemplate(request);
  }

  createConversationWorkflow(conversationWorkflow: ConversationWorkflowModel, conversationId: number): Observable<WorkflowModel> {
    return this.service.createConversationWorkflow(conversationWorkflow, conversationId);
  }

  getTaskTemplates(): void {
    const request: TaskTemplateRequest = {
      status: TaskTemplateStatus.Active,
      useType: [TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.Task], TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.All]],
      sortBy: 'name'
    };

    this.taskService.getTaskTemplates(request)
      .subscribe(taskTemplates => {
        this.taskTemplates.next(taskTemplates.data);
      });
  }

  getEmailTemplates(ids: string[]): void {
    if (ids && ids.length) {
      this.taskService.getEmailTemplates(ids)
        .subscribe(data => {
          this.emailTemplates.next(data);
        });
    }
  }

  getAllSearchedUserGroups(search: string, isConversation: boolean): void {
    this.getUserGroups(search).pipe(

      map((response) => {
        const filteredData = response.data.filter(item => {
          // Filter out items with type 'Group' and status 'Inactive'
          if (item.isGroup === true && item.status === 'Inactive') {
            return false;
          }
          // Include items with types 'CRM Assignment', 'User', or 'All'
          return item.type === GroupTypeStringEnum.CRMAssignment || item.type === 'User' || item.type === GroupTypeStringEnum.All;
        });
        return {
          ...response,
          data: filteredData.map(item => new SearchUserOptionsModel(item))
        };
      })
    ).subscribe(response => {
      isConversation ? this.conversationUsers.next(response.data) : this.taskUsers.next(response.data);
    });

  }

}
