<div *ngIf="files?.length && readOnly">
  <div class="title">{{title}}</div>
  <div>
    <div *ngFor="let file of files">
      <div>
        <i class="icon-document doc-icon"></i>
        <a (click)="getFile(file.url, file.name, file.id)"
           class="file-name">
          {{file.name}}
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!fileList.length && !readOnly"
     class="title"
     style="margin-top: 17px">
  {{title}}
</div>
<div *ngIf="fileList.length && !readOnly">
  <div class="header">
    <div class="title">{{title}}</div>
  </div>
  <div class="list">
    <div [formGroup]="form">
      <div formArrayName="fileList">
        <div *ngFor="let file of fileList.controls; index as i">
          <ng-container [formGroupName]="i">
            <div class="file"
                 fxLayout
                 fxLayoutAlign="space-between {{isBannerDdl ? 'center' : 'start'}}">
              <div fxLayout="row">
                <i class="icon-document doc-icon"></i>
                <div fxLayout="column">
                  <a class="file-name"
                     (click)="getFile(file.controls['url'].value, file.controls['name'].value, file.controls['id'].value)">
                    {{file.controls['name'].value}}
                  </a>
                  <mat-error *ngIf="file.controls['size'].errors"
                             class="err-msg"
                             fxFlex="100">
                    This file is too large to upload. The maximum supported file size is: <strong>10 Mb</strong>.
                  </mat-error>
                  <mat-error *ngIf="file.controls['name'].errors"
                             class="err-msg"
                             fxFlex="100">
                    Invalid File Extension. The supported file extensions are: <strong>png, pdf, doc, docx, jpeg</strong>.
                  </mat-error>
                </div>
              </div>
              <div class="banner-select"
                   fxLayout="row"
                   fxLayoutAlign="flex-start center"
                   fxLayoutGap="21px">
                <div *ngIf="isBannerDdl">
                  <span class="position-label">Position:</span>
                  <mat-form-field appearance="outline">
                  <mat-select (selectionChange)="addPosition(file, $event.value, i)"
                              formControlName="position">
                    <mat-option
                      *ngFor="let banner of bannerOptions"
                      [value]="banner.value">
                      {{banner.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                </div>
                <i (click)="removeFileFromList(i, file)"
                   *ngIf="statusDetails !== 'Completed'"
                   class="icon-trash trash">
                </i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
<div *ngIf="!readOnly && statusDetails !== 'Completed' && !(max === fileList.controls.length)"
     class="container"
     fxLayout
     fxLayoutAlign="center center">
  <div class="text"
       fxLayout
       fxLayoutAlign="center center">
    <i class="icon-add_document add-document"></i>
    <span class="text-info">
      You can upload any of the following file types: {{extensions.join(', ').toUpperCase()}}
    </span>
  </div>
  <input (change)="fileBrowse($event.target.files)"
         [disabled]="max === fileList.controls.length"
         id="file"
         type="file"
         multiple
         accept="{{allowedExtensions.toString()}}">
</div>
<div *ngIf="isError">
  <ul class="error-list">
    <li *ngFor="let error of errorArr.slice(0,10)">{{error}}</li>
  </ul>
</div>
