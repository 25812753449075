<div [className]="addingSaleState ? 'inner-action active' : 'inner-action'">
  <cm-button
    color="composite"
    class="inner-action-button"
    (click)="clearFormSale()"
    textPosition="left"
    active="{{ addingSaleState ? 'active' : '' }}"
  >
    <i class="icon-sale inner-action-icon"></i>Add Sale<i
      class="icon-cross"
    ></i>
  </cm-button>
  <form [formGroup]="formSale">
    <div class="inner-fields">
      <div>
        <mat-form-field fxFlex="50" appearance="outline">
          <mat-label>Application I.D.</mat-label>
          <input
            matInput
            placeholder="Application I.D."
            formControlName="applicationId"
          />
        </mat-form-field>

        <mat-form-field [style.width.%]="50" style="padding-left: 8px"  appearance="outline">
          <mat-label>Branch</mat-label>
          <mat-select
            formControlName="branchId"
            [(value)]="defaultBranch && defaultBranch.value"
          >
            <ng-container *ngFor="let option of userBranchOptions">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Campaign</mat-label>
          <mat-select formControlName="campaignId">
            <ng-container *ngFor="let option of campaignOptions">
              <mat-option [value]="option.value">{{ option.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>

      <div>
        <div class="sub-title">Products</div>
        <div *ngFor="let product of productsList">
          <app-product-card
            [id]="product"
            (deleteItem)="delete($event)"
            [createMode]="true"
            (savedItem)="saveProduct($event)"
          ></app-product-card>
        </div>
        <div class="product-btns" fxLayoutGap="12px" fxFill>
          <cm-button color="secondary" fxFlex="100" (click)="addProduct()"
            >Add Product</cm-button
          >
        </div>
        <!-- <div fxLayout="row wrap"
             fxLayoutAlign="start center">
          <app-referrer-card (chosenInstances)="addReferrerInstance($event)"></app-referrer-card>
        </div> -->
      </div>
    </div>
  </form>
</div>
