import { SelectOption } from '@shared/interfaces';
import { TaskTemplateUseTypeEnum, TaskTemplateUseTypeStringEnum } from '@shared/models/task-template-use-type.model';
import { TaskTemplatePriorityEnum, TaskTemplatePriorityStringEnum, TaskTypeEnum } from '@shared/enums';

export const TypeOptions: SelectOption<string>[] = [
  {
    value: TaskTypeEnum[TaskTypeEnum.General],
    label: 'General'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Email],
    label: 'E-mail'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Phone],
    label: 'Phone'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.FollowUp],
    label: 'Follow Up'
  }
];

export const PriorityOptions: SelectOption<string>[] = [
  {
    value: TaskTemplatePriorityEnum[TaskTemplatePriorityEnum.Low],
    label: TaskTemplatePriorityStringEnum.Low
  },
  {
    value: TaskTemplatePriorityEnum[TaskTemplatePriorityEnum.Medium],
    label: TaskTemplatePriorityStringEnum.Medium
  },
  {
    value: TaskTemplatePriorityEnum[TaskTemplatePriorityEnum.High],
    label: TaskTemplatePriorityStringEnum.High
  },
];
export const DueDateType: SelectOption<string>[] = [
  {
    value: 'Days',
    label: 'Day(s)'
  },
  {
    value: 'Weeks',
    label: 'Week(s)'
  },
  {
    value: 'Years',
    label: 'Year(s)'
  }
];

export const UseTypeOptions: SelectOption<string>[] = [
  {
    value: TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.All],
    label: TaskTemplateUseTypeStringEnum.All
  },
  {
    value: TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.Workflow],
    label: TaskTemplateUseTypeStringEnum.Workflow
  },
  {
    value: TaskTemplateUseTypeEnum[TaskTemplateUseTypeEnum.Task],
    label: TaskTemplateUseTypeStringEnum.Task
  }
];
