import { Moment } from 'moment';
import * as moment from 'moment';
import * as mt from 'moment-timezone';

export const DEFAULT_DATE_FORMAT = 'DD-MM-yyyy';
export const DATE_WITH_TIME_FORMAT = 'DD-MM-yyyy HH:mm';
export const DATE_FORMAT_FOR_TASK_REPORTS = 'yyyy-MM-DD';

export function formatDateToString(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  const endOfDate = moment(date).endOf('day').utc();
  if (endOfDate.utc().format() <= moment(new Date()).utc().format()) {
    return `overdue (${moment(endOfDate, 'YYYYMMDD').fromNow(true)})`;
  }
  return moment(date).format(dateFormat);
}

export function formatDateSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment(date).format(dateFormat);
}

export function formatUTCDateSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment.utc(date).format(dateFormat);
}

export function formatDateToLocalSimple(date: Date | Moment | string, dateFormat: string = DEFAULT_DATE_FORMAT): string {
  return moment(new Date(date.toLocaleString() + 'Z')).format(dateFormat);
}

export function getEndOfDate(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate;
}

export function getSameStartOfDate(date: Date): Date {
  const parseDate = new Date(date);
  parseDate.setUTCHours(0, 0, 0, 0);
  return parseDate;
}

export function getSameEndOfDate(date: Date): Date {
  const parseDate = new Date(date);
  parseDate.setUTCHours(23, 59, 59, 999);
  return parseDate;
}

export function getStartOfDate(date: Date): Date {
  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  return newDate;
}

export function getLocalDateAsString(date: Date): string {
  return moment(getStartOfDate(new Date(date))).format();
}

export function getClientTimezoneOffset(): number {
  return -new Date().getTimezoneOffset();
}

export function getAge(birth, death?): number {
  const birthDate = new Date(birth);
  const fromDate = death ? new Date(death) : new Date();
  return moment(fromDate).diff(birthDate, 'years');
}

export function formatDateSimpleforReport(date: Date | Moment | string, dateFormat: string = DATE_FORMAT_FOR_TASK_REPORTS): string {
  return moment(date).format(dateFormat);
}
export function formateDateTimeSimple(date: Date | string, dateFormat: string = 'YYYY-MM-DD, HH:mm:ss'): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment.utc(date).format(dateFormat);
}
export function profileDateTimeformate(date: Date | string, dateFormat: string = 'DD-MM-YYYY, HH:mm'): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return moment.utc(date).format(dateFormat);
}
export function formateUTCtoEST(date: Date | string, dateFormat: string = 'YYYY-MM-DD, HH:mm:ss'): string {
  const easternMoment = mt(date).tz('America/New_York');
  return easternMoment.format(dateFormat);
}
