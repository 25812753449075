import { TaskTemplateDto } from '@core/services/dto';
import { AttachmentModel } from '@shared/models/attachment.model';

export class TaskTemplate {
  id?: number;
  type: string;
  name: string;
  priority: string;
  description: string;
  createdBy?: {
    userId: string;
    firstName: string;
    lastName: string;
  };
  timeDue?: {
    dueIn: number;
    dueDateType: string;
  };
  status: string;
  useType: string;
  emails?: number[];
  attachments: AttachmentModel[];
  assigneeId?: {
    firstName: string,
    lastName: string,
    id: string,
    name: string,
    isGroup: boolean,
    status: string,
    type: string
  };

  constructor(dto: TaskTemplateDto) {
    this.id = dto.id;
    this.type = dto.type;
    this.name = dto.name;
    this.priority = dto.priority;
    this.description = dto.description;
    this.status = dto.status;
    this.useType = dto.useType;
    this.createdBy = dto.createdBy;
    this.timeDue = dto.timeDue;
    this.attachments = dto.attachments;
    this.emails = dto.emails.map(e => e.id);
    this.assigneeId = dto.assigneeId;
  }
}
