import { MemberProfileModel } from '@shared/models/index';

export class SearchMemberOptionsModel {
  label?: string;
  value?: string;
  ref: string;
  type: string;
  disabled?: boolean;

  constructor(dto: MemberProfileModel) {
    if (dto.memberInfo.organizationName) {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.organizationName}`;
      this.value = dto.memberInfo.organizationName;
    } else {
      this.label = `${dto.memberNumber} - ${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
      this.value = `${dto.memberInfo.firstName} ${dto.memberInfo.lastName}`;
    }
    this.type = 'member';
    this.ref = dto.memberId?.toString();
  }
}
