import { EcmFileDetailsDto, TagsDto } from '@core/services/dto';
import { AttachmentModel } from '@shared/models/attachment.model';
import { formatDateSimple } from '@shared/utils';
import { EcmUrls } from '@core/constants';
import { LinkDto } from '@core/services/dto/link.dto';

export class EcmFileDetailsModel {
  createdBy: {
    id: string,
    firstName: string,
    lastName: string,
    email: string
  };
  createdDate: string;
  lastModifiedDate: string;
  lastArchivedOrRestore: string;
  lastArchivedOrRestoreBy: string;
  description: string;
  isArchived: boolean;
  fileMetadata: {
    id: number,
    name: string,
    contentType: string,
    size: number,
    url: string
  };
  subType: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  id: number;
  name: string;
  path: [
    id: number,
    name: string
  ];
  tags: TagsDto[];
  attachments: AttachmentModel[] = [];
  createdByFullName: string;
  links: LinkDto[];

    constructor(dto: EcmFileDetailsDto) {
      for (const prop in dto) {
        if (dto.hasOwnProperty(prop)) {
          this[prop] = dto[prop];
        }
      }
      this.fileMetadata.url = EcmUrls.loadFile;
      this.createdDate = dto.createdDate ? formatDateSimple(dto.createdDate) : dto.createdDate;
      this.lastModifiedDate = dto.lastModifiedDate ? formatDateSimple(dto.lastModifiedDate) : dto.lastModifiedDate;
      this.createdByFullName = `${dto.createdBy.firstName} ${dto.createdBy.lastName}`;
      this.attachments.push(this.fileMetadata);
      this.lastArchivedOrRestore = dto.lastArchivedOrRestore ? formatDateSimple(dto.lastArchivedOrRestore) : dto.lastArchivedOrRestore;
  }
}
