import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateConversationCommonComponent } from '@shared/components/conversation/create-conversation-common.component';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { ConversationType } from '@shared/configs/conversation-types.config';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { RESOLUTION_OPTIONS } from '@shared/constants/select-options.constants';
import { AssigneeFormControl } from '@shared/controls/assignee-form-control';
import { ConversationModel, SearchMemberOptionsModel, SearchUserOptionsModel } from '@shared/models';
import { resolutionDetailsValidator } from '@shared/validators/concern-resolution-details.validator';
import { Subject, Observable } from 'rxjs';
import { FileTypeEnum } from '@shared/enums';
import { map } from 'rxjs/operators';
import { ConversationApiService, TaskApiService, UserGroupManagementApiService } from '@core/services/api.services';
import { EnailNotificationApiService } from '@core/services/api.services/email-notification-api.service';
import { UserManagementStateService } from 'app/modules/setup/user-management/user-management.state.service';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Component({
  selector: 'app-create-concern',
  templateUrl: './create-concern.component.html',
  styleUrls: ['../create-conversation/create-conversation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateConcernComponent extends CreateConversationCommonComponent implements OnInit, AfterViewInit {
  defaultAssigneeUserName = new Subject<SearchUserOptionsModel>();
  form: FormGroup;
  conversationUsers$ = this.state.conversationUsers$();
  selectedUser: any;
  isRepRole = false;


  FileTypeEnum = FileTypeEnum;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  resolutionOptions = RESOLUTION_OPTIONS;
  constructor(
    fb: FormBuilder,
    state: CreateConversationStateService,
    toast: CustomSnackbarService,
    globalState: GlobalStateService,
    taskService: TaskApiService,
    conversationService: ConversationApiService,
    htmlElementRef: ElementRef,
    service: EnailNotificationApiService,
    private userstate: UserManagementStateService,
    private groupState: UserGroupManagementApiService) {
    super(fb, state, globalState, toast, taskService, conversationService, htmlElementRef, service);

    this.form.get('conversationType').patchValue(ConversationType.concern);

    this.form.addControl('concernType', this.fb.control('', Validators.required));
    this.form.addControl('resolution', this.fb.control('Unresolved', Validators.required));
    this.form.addControl('resolutionDetails', this.fb.control(''));
    this.form.addControl('assigneeId', new FormControl(''));

    this.form.setValidators(resolutionDetailsValidator(this.concernResolved));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectedUser = {
      label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
      ref: this.globalState.user.id
    };

    this.form.get('assigneeId').valueChanges.subscribe(nameselectedUser => {
      if (!nameselectedUser) {
        this.isRepRole = false;
        return;
      }

      if (nameselectedUser?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.state.getSearchedUserGroups(nameselectedUser, true);
      }

    });
  }

  ngAfterViewInit(): void {
    this.checkFormChanges();
  }


  getGroupByUserId(id: any): void {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (pattern.test(id) === false) {
      this.groupState.getGroup(id).subscribe((response) => {
        const res = response;
        if (res.type === GroupTypeStringEnum.All ||
          response.type === GroupTypeStringEnum.CRMandRepresentativeRole ||
          response.type === GroupTypeStringEnum.CRMAssignment ||
          response.type === GroupTypeStringEnum.RepresentativeRole) {
          this.isRepRole = true;
        }
      });
    }
  }

  onOptionSelected(event: any): void {
    if (event && event.hasOwnProperty('label')) {
      this.getGroupByUserId(event.ref);
    }
  }

  saveConversationType(): Observable<ConversationModel> {
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }
      });
    }
    const concern = {
      subject: this.form.value.subject,
      description: this.form.value.description,
      status: this.form.value.status,
      concernType: this.form.value.concernType,
      productId: this.form.value.productId,
      resolution: this.form.value.resolution,
      resolutionDetails: this.form.value.resolutionDetails,
      assigneeId: this.form.value.assigneeId.ref,
      // assigneeId: this.selectedUser ? this.selectedUser.ref : '',
      attachments: this.form.value.attachments.map(x => x.id),

      links: this.formLinks,
    };
    return this.state.setConcern(concern);
  }


  concernResolved(value: string): boolean {
    return value === 'Resolved';
  }

  getOptionText(option): any {
    return option?.label;
  }
}
