<ng-container *ngFor="let tableColumn of tableConfig">
  <div class="col__item" [ngClass]="tableColumn.templateName === 'member' ? 'mat-column-Member' : ''"
       [style.width]="tableColumn.width">
    <ng-container [ngSwitch]="tableColumn.templateName">
      <ng-container *ngSwitchCase="'link'">
        <a (click)="emitClick(col)" class="link">{{col[tableColumn.dataKey]}}</a>
      </ng-container>
      <ng-container *ngSwitchCase="'percent'">
        <div class="text-ellipsis"
             *ngIf="tableColumn.dataKey !== head?.key">{{col[tableColumn.dataKey] | number: '.2-2'}}%</div>
      </ng-container>
      <ng-container *ngSwitchCase="'description'">
        <ng-container *ngIf="tableColumn.dataKey === head?.key">
          <div class="pointer" (click)="onIconClick()">
              <span class="icon-container">
            <i *ngIf="!visible" class="action-icon icon-arrow_down"></i>
            <i *ngIf="visible" class="action-icon icon-arrow_up"></i>
                 <span>{{col[tableColumn.dataKey]}}</span>
          </span>
          </div>
        </ng-container>
        <div class="text-ellipsis"
             *ngIf="tableColumn.dataKey !== head?.key
              && tableColumn.dataKey !== col?.excludedColumn">{{col[tableColumn.dataKey]}}</div>
      </ng-container>
      <ng-container *ngSwitchCase="'currency'">
        <div class="text-ellipsis"
             *ngIf="tableColumn.dataKey !== head?.key">{{col[tableColumn.dataKey] | currency}}</div>
      </ng-container>
      <ng-container *ngSwitchCase="'member'">
        <app-member-list
          [ngClass]="tableColumn.className"
          [linksList]="col[tableColumn.dataKey]"
          [memberProfile]="col[tableColumn.dataKey]"></app-member-list>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
