import { Injectable } from '@angular/core';
import {
  AccountsApiService,
  ConversationApiService,
  MemberProfileApiService,
  NoteApiService,
  TaskApiService
} from '@core/services/api.services';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LinkSearchRequestDto } from '@shared/models/requests/link-search.request.dto';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import {
  SearchTaskModel,
  SearchTaskOptionsModel,
  SearchConversationOptionsModel,
  SearchConversationModel,
  SearchMemberOptionsModel,
  SearchMemberModel,
  SearchAccountModel,
  SearchAccountOptionsModel,
  SearchNoteModel,
  SearchNoteOptionsModel
} from '@shared/models';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';

@Injectable({providedIn: 'any'})

export class LinkItemStateService extends autoUnsubscribeMixin() {

  public members: BehaviorSubject<SearchMemberOptionsModel[]> = new BehaviorSubject<SearchMemberOptionsModel[]>([]);
  public prospects: BehaviorSubject<SearchMemberOptionsModel[]> = new BehaviorSubject<SearchMemberOptionsModel[]>([]);
  public conversations: BehaviorSubject<SearchConversationOptionsModel[]> = new BehaviorSubject<SearchConversationOptionsModel[]>([]);
  public tasks: BehaviorSubject<SearchTaskOptionsModel[]> = new BehaviorSubject<SearchTaskOptionsModel[]>([]);
  public notes: BehaviorSubject<SearchNoteOptionsModel[]> = new BehaviorSubject<SearchNoteOptionsModel[]>([]);
  public accounts: BehaviorSubject<SearchAccountOptionsModel[]> = new BehaviorSubject<SearchAccountOptionsModel[]>([]);

  constructor(
    private memberProfileService: MemberProfileApiService,
    private conversationApiService: ConversationApiService,
    private taskApiService: TaskApiService,
    private noteApiService: NoteApiService,
    private accountsApiService: AccountsApiService,
  ) {
    super();
  }

  members$(): Observable<any> {
    return this.members.asObservable();
  }

  prospects$(): Observable<any> {
    return this.prospects.asObservable();
  }

  conversations$(): Observable<any> {
    return this.conversations.asObservable();
  }

  tasks$(): Observable<any> {
    return this.tasks.asObservable();
  }

  accounts$(): Observable<any> {
    return this.accounts.asObservable();
  }

  notes$(): Observable<any> {
    return this.notes.asObservable();
  }

  getSearchedMembers(request: LinkSearchRequestDto): void {
    this.memberProfileService.searchMember(request).pipe(
      map((response => new SearchMemberModel(response)))
    ).subscribe(
      res => {
        const memberOptions = [];
        res.data.forEach(item => {
          const member = new SearchMemberProspectOptionsModel(item);
          memberOptions.push(member);
        });
        this.members.next(memberOptions);
      }
    );
  }

  getSearchedProspects(request: LinkSearchRequestDto): void {
    this.memberProfileService.searchMember(request).pipe(
      map((response => new SearchMemberModel(response)))
    ).subscribe(
      res => {
        const prospectsOptions = [];
        res.data.forEach(item => {
          const prospects = new SearchMemberProspectOptionsModel(item);
          if (prospects.isProspectInfo) {
            prospectsOptions.push(prospects);
          }
        });
        this.prospects.next(prospectsOptions);
      }
    );
  }

  getSearchedConversations(request: LinkSearchRequestDto): void {
    this.conversationApiService.getSearchConversations(request).pipe(
      map((response => new SearchConversationModel(response)))
    ).subscribe(
      res => {
        const conversationOptions = [];
        res.data.forEach(item => {
          const conversation = new SearchConversationOptionsModel(item);
          conversationOptions.push(conversation);
        });
        this.conversations.next(conversationOptions);
      }
    );
  }

  getSearchedTasks(request: LinkSearchRequestDto): void {
    this.taskApiService.getTasks(request).pipe(
      map((response => new SearchTaskModel(response)))
    ).subscribe(
      res => {
        const TasksOptions = [];
        res.data.forEach(item => {
          const task = new SearchTaskOptionsModel(item);
          TasksOptions.push(task);
        });
        this.tasks.next(TasksOptions);
      }
    );
  }

  getSearchedNotes(request: LinkSearchRequestDto): void {
    this.noteApiService.getNotes(request).pipe(
      map((response => new SearchNoteModel(response)))
    ).subscribe(
      res => {
        const NoteOptions = [];
        res.data.forEach(item => {
          const note = new SearchNoteOptionsModel(item);
          NoteOptions.push(note);
        });
        this.notes.next(NoteOptions);
      }
    );
  }

  getSearchedAccounts(request: LinkSearchRequestDto, members): void {
    this.accountsApiService.getAccounts(request).pipe(
      map((response => new SearchAccountModel(response)))
    ).subscribe(
      res => {
        const AccountOptions = [];
        res.data.forEach(item => {
          item.memberName = members.find(member => member.ref === item.memberId.toString())?.value;
          const account = new SearchAccountOptionsModel(item);
          AccountOptions.push(account);
        });
        this.accounts.next(AccountOptions);
      }
    );
  }
}
