import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { GlobalStateService } from '@core/services/global.state.service';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { UserBranchModel } from '@shared/models/user-Branch.model';
import { ContactTypeEnum } from '@shared/enums';
import { MemberProfileModel, ProvinceModel, SearchAccountOptionsModel, SearchConversationOptionsModel, SearchMemberOptionsModel } from '@shared/models';
import { LeadTypeEnum } from '@shared/enums/lead-type.enum';
import {
  BusinessPhoneTypeOptions,
  BusinessRoleTypeOptions, BusinessTypeOptions, LeadTypeOptions, PhoneTypeOptions,
  PrefferedContactOptions, ProspectStatusOptions, SaluationOptions
} from '../create-prospect.config';
import { BusinessTypeEnum } from '@shared/enums/business-type.enum';
import { BusinessPhoneTypeEnum, PhoneTypeEnum } from '@shared/enums/phone-type.enum';
import { BusinessRoleTypeEnum } from '@shared/enums/business-role-type.enum';
import { SaluationEnum } from '@shared/enums/saluation.enum';
import { CreateProspectStateService } from '../create-prospect.state.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProspectTypeEnum } from '@shared/enums/prospect-type.enum';
import { ProspectStatusEnum } from '@shared/enums/prospect-status.enum';
import { ProspectCreate } from '@shared/models/prospect-create';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MemberProspectInstancesModel } from '@shared/models/memberprospectinstances.model';
import { AddressDto, BranchDto } from '@core/services/dto';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { SelectOption } from '@shared/interfaces';
import { CountryOptions } from 'app/modules/member-profile/pages/member-info/address-info/address-info.config';
import { BankIntegrationConfigurationDto } from '@shared/models/bank-integration-configuration.dto';
import { takeUntil } from 'rxjs/operators';
import { CanadaPostAddressModel } from '@shared/components/canada-post-address/models/canada-post-address.model';
import { CanadaPostAddressStateService } from '@shared/components/canada-post-address/canada-post-address.state.service';

@Component({
  selector: 'app-create-prospect',
  templateUrl: './create-prospect.component.html',
  styleUrls: ['./create-prospect.component.scss']
})

export class CreateProspectComponent extends autoUnsubscribeMixin()
  implements OnInit, AfterViewInit {
  form: FormGroup;
  branches: BranchDto[];
  prospect: ProspectCreate;
  formLinks: SearchMemberProspectOptionsModel[] = [];
  ProspectTypeEnum = ProspectTypeEnum;
  userBranchOptions: UserBranchModel[];
  LeadTypeOptions = LeadTypeOptions;
  PhoneTypeOptions = PhoneTypeOptions;
  BusinessPhoneTypeOptions = BusinessPhoneTypeOptions;
  BusinessTypeOptions = BusinessTypeOptions;
  ProspectStatusOptions = ProspectStatusOptions;
  private orginalBusinessRoleTypeOptions = BusinessRoleTypeOptions;
  public BusinessRoleTypeOptions = [];
  PrefferedContactOptions = PrefferedContactOptions;
  SaluationOptions = SaluationOptions;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  viewInit = false;
  cuName = '';
  defaultBranch: UserBranchModel;
  addressAddingType: 'search' | 'manually' = 'search';
  isEditing = false;
  isAdding = false;
  bankIntegrationConfiguration: BankIntegrationConfigurationDto;
  addresses: AddressDto[] = [];
  private provinces: ProvinceModel[] = [];
  member: MemberProfileModel;
  public filteredProvinces: ProvinceModel[] = [];
  countryCode: string;
  condensedInternational: any;
  allCountries: SelectOption<string>[] = CountryOptions;


  @Input() preselectedMembers: SearchMemberOptionsModel[] = [];
  @Input() preselectedConversation: SearchConversationOptionsModel;
  @Input() preselectedAccounts: SearchAccountOptionsModel[] = [];
  @Input() preselectedMembersProspects: SearchMemberProspectOptionsModel[] = [];

  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Input() Id?;

  @Output() formChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  initialValue: any = {};
  private streetValidators = [Validators.maxLength(28)];
  initialAddress: any = {
    addressLine1: '',
    city: '',
    provinceId: '',
    postalCode: '',
  };
  constructor(
    private fb: FormBuilder,
    private state: CreateProspectStateService,
    private toast: CustomSnackbarService,
    private router: Router,
    private globalStateService: GlobalStateService,
    private canadaPostAddressStateService: CanadaPostAddressStateService,

  ) {
    super();
    this.form = this.fb.group({
      prospectType: [null, Validators.required],
      branchId: [null],
      prospectStatus: [ProspectStatusEnum[ProspectStatusEnum.Active]],
      // Retailfields
      retailLead: [LeadTypeEnum['']],
      retailPreferredContact: [ContactTypeEnum['']],
      retailSaluation: [SaluationEnum[''], Validators.required],
      retailFirstName: [null],
      retailLastName: [null],
      retailNickName: [null],
      retailEmail: [null],
      retailPhoneNumber: [null],
      retailPhone: [PhoneTypeEnum['']],
      retailConsent: [false],
      // Businessfields
      businessConsent: [false],
      businessPreferredContact: [ContactTypeEnum['']],
      businessLead: [LeadTypeEnum['']],
      businessType: [BusinessTypeEnum['']],
      businessName: [null],
      businessEmail: [null],
      businessPhoneNumber: [null],
      businessPhone: [BusinessPhoneTypeEnum['']],
      contactRole: [BusinessRoleTypeEnum['']],
      contactNickName: [null],
      contactSaluation: [SaluationEnum['']],
      contactFirstName: [null],
      contactLastName: [null],
      contactEmail: [null],
      contactPhoneNumber: [null],
      contactPhone: [BusinessPhoneTypeEnum['']],
      // Addressfields
      id: '',
      type: [null, Validators.required],
      poBox: [false],
      street: [''], // Station for PO box
      streetNumber: [''], // P.O Box Number for PO box
      apartment: [''],
      city: [''],
      country: ['CA'],
      provinceId: [''],
      provinceName: [''],
      addressLine1: [''],
      addressLine2: [''],
      postalCode: [''],
      isPrimary: true
    });

  }

  async ngOnInit(): Promise<void> {
    this.globalStateService.getBranch();

    const provincesResponse = await this.state.getProvinces().toPromise();
    this.provinces = provincesResponse.data;

    this.globalStateService.userBranches$()
    .pipe(takeUntil(this.destroyed$))
    .subscribe({
      next: (data) => {
        this.userBranchOptions = data;
        this.defaultBranch = this.userBranchOptions.find(branch => branch.isDefault === true);
        this.form.patchValue({
          branchId: this.defaultBranch?.value
        });
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });

    this.globalStateService.getSiteDetails()
    .pipe(takeUntil(this.destroyed$))
    .subscribe((data: any) => {
      this.cuName = data.legalName;
    });

    this.globalStateService.bankIntegrationConfiguration$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(config => {
        this.bankIntegrationConfiguration = config;

        this.form.patchValue({
          type: this.getAvailableTypeOptions()[0].value,
          country: this.allCountries[0].value
        });
        this.selectionChange({value: this.allCountries[0].value});
      });

    this.form.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(val => {
      const retailLead = 'retailLead';
      const retailFirstName = 'retailFirstName';
      const retailConsent = 'retailConsent';
      const branchId = 'branchId';
      const retailEmail = 'retailEmail';
      const retailPhoneNumber = 'retailPhoneNumber';
      const businessLead = 'businessLead';
      const businessName = 'businessName';
      const contactRole = 'contactRole';
      const contactFirstName = 'contactFirstName';
      const businessConsent = 'businessConsent';
      const businessEmail = 'businessEmail';
      const retailPreferredContact = 'retailPreferredContact';
      const businessPhoneNumber = 'businessPhoneNumber';
      const contactEmail = 'contactEmail';
      const retailSaluation = 'retailSaluation';
      const contactPhoneNumber = 'contactPhoneNumber';
      const retailLastName = 'retailLastName';
      const retailNickName = 'retailNickName';
      const retailPhone = 'retailPhone';
      const businessPhone = 'businessPhone';
      const contactPhone = 'contactPhone';
      const businessPreferredContact = 'businessPreferredContact';

      if (val.prospectType === ProspectTypeEnum.Individual) {
        //#region New Validations
        this.form.controls[retailPreferredContact].setValidators([Validators.required]);
        if (val.retailPreferredContact === 'Email') {
          this.form.controls[retailEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[retailPhone].clearValidators();
          this.form.controls[retailPhoneNumber].clearValidators();
          this.form.controls[retailPhoneNumber].setValidators([
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        }
        if (val.retailPreferredContact === 'Phone') {
          this.form.controls[retailPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[retailEmail].clearValidators();
          this.form.controls[retailEmail].setValidators([
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[retailPhone].setValidators([Validators.required]);
          this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        }
        //#endregion
        this.form.controls[retailLead].setValidators([Validators.required]);
        this.form.controls[retailFirstName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[branchId].setValidators([Validators.required]);
        this.form.controls[retailConsent].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[branchId].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailLead].updateValueAndValidity({ emitEvent: false });
        // it is used to relocate the position of the dialog
        // window.dispatchEvent(new Event('resize'));
      }
      if (val.prospectType === ProspectTypeEnum.Business) {
        this.form.controls[retailLead].clearValidators();
        this.form.controls[retailPreferredContact].clearValidators();
        this.form.controls[retailSaluation].clearValidators();
        this.form.controls[retailFirstName].clearValidators();
        this.form.controls[retailLastName].clearValidators();
        this.form.controls[retailNickName].clearValidators();
        this.form.controls[retailEmail].clearValidators();
        this.form.controls[retailPhoneNumber].clearValidators();
        this.form.controls[retailPhone].clearValidators();
        this.form.controls[retailConsent].clearValidators();
        this.form.controls[retailLead].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPreferredContact].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailSaluation].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailLastName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailNickName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailEmail].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPhoneNumber].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailPhone].updateValueAndValidity({ emitEvent: false });
        this.form.controls[retailConsent].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessLead].setValidators([Validators.required]);
        this.form.controls[businessName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[branchId].setValidators([Validators.required]);
        this.form.controls[contactRole].setValidators([Validators.required]);
        this.form.controls[contactFirstName].setValidators([Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
        ]);
        this.form.controls[businessLead].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[branchId].updateValueAndValidity({ emitEvent: false });
        this.form.controls[contactRole].updateValueAndValidity({ emitEvent: false });
        this.form.controls[contactFirstName].updateValueAndValidity({ emitEvent: false });
        this.form.controls[businessConsent].updateValueAndValidity({ emitEvent: false });
        //#region new validations
        this.form.controls[businessPreferredContact].setValidators([Validators.required]);
        if (val.businessPreferredContact === 'Email') {
          this.form.controls[contactEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[businessPhoneNumber].clearValidators();
          this.form.controls[businessPhoneNumber].setValidators([
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[contactPhoneNumber].clearValidators();
          this.form.controls[contactPhoneNumber].setValidators([
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[businessPhone].clearValidators();
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhoneNumber].updateValueAndValidity({ emitEvent: false });

        }
        if (val.businessPreferredContact === 'Phone') {
          this.form.controls[businessPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[contactPhoneNumber].setValidators([
            Validators.required,
            Validators.pattern(/^\d+$/), // Only numbers
            Validators.minLength(5),
            Validators.maxLength(20)
          ]);
          this.form.controls[businessPhone].setValidators([Validators.required]);
          this.form.controls[businessEmail].clearValidators();
          this.form.controls[contactEmail].clearValidators();
          this.form.controls[contactEmail].setValidators([
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[businessEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[contactPhoneNumber].updateValueAndValidity({ emitEvent: false });
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });

        }
        //#endregion

        if (val.businessEmail) {
          this.form.controls[contactEmail].setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,3}$'),
          ]);
          this.form.controls[contactEmail].updateValueAndValidity({ emitEvent: false });
        }
        if (val.businessPhoneNumber) {
          this.form.controls[businessPhone].clearValidators();
          this.form.controls[businessPhone].setValidators([Validators.required]);
          this.form.controls[businessPhone].updateValueAndValidity({ emitEvent: false });
        }
        if (val.contactPhoneNumber) {
          this.form.controls[contactPhone].clearValidators();
          this.form.controls[contactPhone].setValidators([Validators.required]);
          this.form.controls[contactPhone].updateValueAndValidity({ emitEvent: false });
        }
        // it is used to relocate the position of the dialog
        // window.dispatchEvent(new Event('resize'));
      }
    });

    this.form.get('prospectType').valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe((value) => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  checkFormChange(): void {
    setTimeout(() => {
      this.initialValue = JSON.stringify(this.form.value);
      this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (JSON.stringify(value) !== this.initialValue) {
          this.formChanged.emit(true);
        }
      });
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.checkFormChange();
  }

  add(): void {
    this.prospect = new ProspectCreate(this.form.value);
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }

      });
      this.prospect.ProspectMemberId = this.formLinks[0].ref;
    }
    this.prospect.links = this.formLinks;

    this.state.createProspect(this.prospect)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(data => {
      this.toast.success('Prospect successfully added');
      this.formChanged.emit(false);
      if ('id' in data) {
        this.closeDialog.emit();
        this.router.navigate([`/${AppConfig.routes.prospectProfile.main}`, data.id]);
      }
    }, (error: HttpErrorResponse) => {
      console.log('error', error);
    });
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      acc.push(new MemberProspectInstancesModel(item));
    });
    this.formLinks = acc;
  }


  showToast(data?): void {
    if ('id' in data) {
      this.toast.success('Template successfully saved');
      this.closeDialog.emit();
    }
    else {
      this.toast.fail(data.response);
      this.closeDialog.emit();
    }
  }

  cancel($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeDialog.emit();
  }

  public updateBusinessRoleOptions(event: any): void {
    const formValue = this.form.value;
    this.BusinessRoleTypeOptions = [];
    if (formValue.businessType) {
      switch (formValue.businessType) {
        case this.BusinessTypeOptions[0].value:
          // SoleProprietorship
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          break;
        case this.BusinessTypeOptions[1].value:
          // Partnership
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[1]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          break;
        case this.BusinessTypeOptions[2].value:
          // Association
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[3]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[4]);
          break;
        case this.BusinessTypeOptions[3].value:
          // Corporation
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[0]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[2]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[3]);
          this.BusinessRoleTypeOptions.push(this.orginalBusinessRoleTypeOptions[4]);
          break;
      }
    }
  }

  get isFormValid(): boolean {
    return this.form.valid;
  }

  addNewAddress(): void {
    this.isAdding = true;
    this.setAddressAddingType('search');
  }

  setAddressAddingType(type: 'search' | 'manually'): void {
    this.addressAddingType = type;
    if (type === 'search') {
    }
  }

  trackByFn(opt: SelectOption<string>): string {
    return opt.value;
  }

  getAvailableTypeOptions(): SelectOption<string>[] {
    return this.bankIntegrationConfiguration?.addressTypes
      .filter(x => !this.addresses.some(addr => addr.type === x.type)
        || (this.isEditing && this.form.controls.type.value === x.type))
      .map(x => ({
        value: x.type,
        label: (x.label === 'Other') ? 'Mailing address' : x.label,
      }));
  }


  getAvailableCountryOptions(): SelectOption<string>[] {
    return CountryOptions;
  }


  addressFound(addr: CanadaPostAddressModel): void {
    const tempFind: any = this.provinces.find((resp: any) => resp.key === addr.provinceId);
    this.form.patchValue({
      apartment: addr.apartment,
      street: addr.street,
      streetNumber: addr.streetNumber,
      city: addr.city,
      provinceId: tempFind?.id || addr.provinceId,
      postalCode: addr.postalCode,
      poBox: addr.poBox,
      addressLine1: addr.addressLine1,
      country: 'CA',
      provinceName: addr.provinceName,
      isPrimary: false
    });
    this.selectionChange({ value: addr.country || 'CA' });

    this.setAddressAddingType('manually');
    this.toggleAddressValidation();
  }


  public selectionChange(event: any): any {
    // this.form.get('country').setValue(this.countryCode);
    this.countryCode = event.value;
    this.filteredProvinces = this.provinces.filter((resp: any) => resp.countryCode === this.countryCode);
    this.canadaPostAddressStateService.countryCode.next(this.countryCode);
    this.form.get('provinceId').setValue('');
  }

  public checkStateValidation(): any {
    const countryValue = this.form.get('country').value;
    const tempStatus = this.condensedInternational && (countryValue === 'CA' || countryValue === 'US');
    return tempStatus;
  }

  toggleAddressValidation(): void {
    const { addressLine1, city, provinceId, postalCode } = this.form.value;
    const isChangeNotMade = this.initialAddress.addressLine1 === addressLine1 && this.initialAddress.city === city &&
    this.initialAddress.provinceId === provinceId &&
    this.initialAddress.postalCode === postalCode;

    if (!isChangeNotMade) {
      this.form.get('addressLine1')?.setValidators([Validators.required]);
      this.form.get('city')?.setValidators([Validators.required]);
      this.form.get('provinceId')?.setValidators([Validators.required]);
      this.form.get('postalCode')?.setValidators([Validators.required]);
    }
    else {
      this.form.get('addressLine1')?.clearValidators();
      this.form.get('city')?.clearValidators();
      this.form.get('provinceId')?.clearValidators();
      this.form.get('postalCode')?.clearValidators();
    }

    this.form.get('addressLine1')?.updateValueAndValidity();
    this.form.get('city')?.updateValueAndValidity();
    this.form.get('provinceId')?.updateValueAndValidity();
    this.form.get('postalCode')?.updateValueAndValidity();
  }

}
