export const reportColumnConfig = [
  {
    name: 'Region',
    dataKey: 'region',
    position: 'left',
    isSortable: false,
    templateName: 'region',
    externalKey: 'data',
    hide: false,
    width: '150px'
  },
  {
    name: 'Branch',
    dataKey: 'branch',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    externalKey: 'data',
    hide: false,
    width: '150px'
  },
  {
    name: 'Campaign',
    dataKey: 'campaignName',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '150px'
  },
  {
    name: 'Employee',
    dataKey: 'employee',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '150px'
  },
  {
    name: 'Product',
    dataKey: 'product',
    position: 'left',
    isSortable: false,
    templateName: 'link',
    extraData: 'link',
    hide: false,
    width: '200px'
  },
  {
    name: 'Member',
    dataKey: 'members',
    position: 'left',
    isSortable: false,
    templateName: 'member',
    hide: false,
    width: '150px'
  },
  {
    name: 'Referred By',
    dataKey: 'referrers',
    position: 'left',
    isSortable: false,
    templateName: 'member',
    hide: false,
    width: '150px'
  },
  {
    name: 'Sale Count',
    dataKey: 'salesCount',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '100px'
  },
  {
    name: 'New (I)',
    dataKey: 'newInternalCount',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '100px'
  },
  {
    name: 'New (E)',
    dataKey: 'newExternalCount',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '100px'
  },
  {
    name: 'Renewal',
    dataKey: 'renewalCount',
    position: 'left',
    isSortable: false,
    templateName: 'description',
    hide: false,
    width: '100px'
  },
  {
    name: 'Sales Values',
    dataKey: 'salesValues',
    position: 'left',
    isSortable: false,
    templateName: 'currency',
    hide: false,
    width: '80px'
  },
  {
    name: 'New Internal',
    dataKey: 'newInternalValues',
    position: 'left',
    isSortable: false,
    templateName: 'currency',
    hide: false,
    width: '160px'
  },
  {
    name: 'New External',
    dataKey: 'newExternalValues',
    position: 'left',
    isSortable: false,
    templateName: 'currency',
    hide: false,
    width: '160px'
  },
  {
    name: 'Renewal ',
    dataKey: 'renewalValues',
    position: 'left',
    isSortable: false,
    templateName: 'currency',
    hide: false,
    width: '160px'
  },
];
