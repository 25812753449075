import { Injectable } from '@angular/core';
import { AsappServiceUrls, MemberUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { Observable } from 'rxjs';
import { IdentificationDocumentDto, PaginatedResponseDto } from '@core/services/dto';
import { EmploymentModel } from '@shared/models/employment.model';
import { MemberContactDto } from '@core/services/dto/member-contact.dto';
import { MemberContactModel } from '@shared/models/member-contact.model';
import { MemberContactRequestDto } from '@shared/models/requests/member-contact.request.dto';
import { IdentificationDocumentRequestDto } from '@shared/models/requests/identification-document.request.dto';
import { MemberContactPreferencesModel } from '@shared/models';
import { MemberTypeStringEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class PersonApiService {

  constructor(private api: ApiClient) {
  }

  getPersonEmployment(personalId: number): Observable<PaginatedResponseDto<EmploymentModel>> {
    return this.api.get(MemberUrls.employments(personalId));
  }

  createEmployment(personalId: number, employment: EmploymentModel): Observable<EmploymentModel> {
    return this.api.post(MemberUrls.employments(personalId), employment);
  }

  updateEmployment(personalId: number, employmentId: number, employment: EmploymentModel): Observable<EmploymentModel> {
    return this.api.put(MemberUrls.updateEmployment(personalId, employmentId), employment);
  }

  deleteEmployment(personalId: number, employmentId: number, employment: EmploymentModel): Observable<EmploymentModel> {
    return this.api.delete(MemberUrls.updateEmployment(personalId, employmentId), employment);
  }

  getPersonContacts(ids: number[]): Observable<PaginatedResponseDto<MemberContactDto>> {
    return this.api.get(MemberUrls.personContacts, { includedPersonIds: ids });
  }

  getOrgContacts(ids: number[]): Observable<PaginatedResponseDto<MemberContactDto>> {
    return this.api.get(MemberUrls.organizationContacts, { IncludedOrganizationIds: ids });
  }

  getContacts(id: number, type: string): Observable<PaginatedResponseDto<MemberContactDto>> {
    return type === MemberTypeStringEnum.Business ? this.api.get(MemberUrls.orgContacts(id)) : this.api.get(MemberUrls.contacts(id));
  }

  getContactPreferences(id: number, type: string): Observable<MemberContactPreferencesModel> {
    return type === MemberTypeStringEnum.Business ? this.api.get(MemberUrls.orgPreferences(id)) : this.api.get(MemberUrls.preferences(id));
  }

  updateContactPreferences(id: number, memberType: string,
                           request: MemberContactPreferencesModel): Observable<MemberContactPreferencesModel> {
    return memberType === MemberTypeStringEnum.Business
      ? this.api.put(MemberUrls.orgPreferences(id), request)
      : this.api.put(MemberUrls.preferences(id), request);
  }

  createContact(memberId: number, type: string, contact: MemberContactRequestDto): Observable<MemberContactModel> {
    return type === MemberTypeStringEnum.Business ? this.api.post(MemberUrls.orgContacts(memberId), contact)
      : this.api.post(MemberUrls.contacts(memberId), contact);
  }

  updateContact(memberId: number, type: string, contact: MemberContactRequestDto): Observable<MemberContactModel> {
    return type === MemberTypeStringEnum.Business ? this.api.put(MemberUrls.updateOrgContact(memberId, contact.id), contact)
      : this.api.put(MemberUrls.updateContact(memberId, contact.id), contact);
  }

  deleteContact(id: number, type: string, contact: MemberContactRequestDto): Observable<MemberContactModel> {
    return type === MemberTypeStringEnum.Business ? this.api.delete(MemberUrls.deleteOrgContact(id, contact.id), contact)
      : this.api.delete(MemberUrls.deleteContact(id, contact.id), contact);
  }

  getIdentificationDocuments(personalId: number, type: string, request: IdentificationDocumentRequestDto)
    : Observable<PaginatedResponseDto<IdentificationDocumentDto>> {
    return this.api.get(MemberUrls.identificationDocuments(personalId, type), request);
  }

  getOccupations(): Observable<string[]> {
    return this.api.get(AsappServiceUrls.occupations);
  }

  getAutoComplete(): Observable<boolean> {
    return this.api.get(AsappServiceUrls.autocomplete);
  }
}
