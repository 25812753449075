import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmailTemplateDto } from '@core/services/dto';
import { SearchEmailTemplatesOptionsModel } from '@shared/models';
import { DATE_WITH_TIME_FORMAT, formatDateToLocalSimple } from '@shared/utils';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnInit, OnChanges {
  @Input() templates: EmailTemplateDto[] | SearchEmailTemplatesOptionsModel[];
  @Input() mode: string;
  @Input() isSendDisabled: boolean;
  @Input() memberError = false;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendEmail: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates && changes.templates.currentValue) {
      if (this.templates && this.templates.length && this.mode === 'view') {
        this.templates.forEach(item => {
          item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
        });
      }
    }
  }

  send(event): void {
    this.sendEmail.emit(event);
  }

  remove(event): void {
    this.delete.emit(event);
  }
}
