import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackDropService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  showBackdrop(): void {
    const backdrop = this.document.querySelector('#custom-backdrop') as HTMLDivElement;
    backdrop.style.display = 'block';
  }

  hideBackDrop(): void {
    const backdrop = this.document.querySelector('#custom-backdrop') as HTMLDivElement;
    backdrop.style.display = 'none';
  }
}
