<ng-container>
  <div class="container">
    <div class="sub-title">
      Referred by
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center">
      <app-linked-instance *ngFor="let item of chosenLinks"
                           [instance]="item"
                           [readOnly]="false"
                           [showLabel]="true"
                           (removeItem)="removeFromChosenLinks($event)"></app-linked-instance>
    </div>
    <div *ngIf="!btnComponent"
         fxLayout
         class="links-container">
      <div class="link-item">
        <cm-button color="gray"
                   (click)="openLink()"
                   [disabled]="chosenLinks?.length > 4">
          Link Referrer
        </cm-button>
      </div>
    </div>
    <form [formGroup]="form">
      <app-input-autocomplete *ngIf="inputVisible"
                              formControlName="referredBy"
                              placeholder="Referred By"
                              [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                              [options]="referredByUsers$ | async"
                              [defaultValue]="defaultSoldByUserName"
                              (clicked)="addInstance($event)">
      </app-input-autocomplete>
    </form>
  </div>
</ng-container>
