<div class="email-template-container">
  <div class="title">
    Email Template
  </div>
  <div class="member-error" *ngIf="memberError">
    Not all linked members have an email address. Fill in the information and try again.
  </div>
  <div class="sub-title" *ngFor="let template of templates">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <i *ngIf="template.sendDate" class="icon-envelope-small"></i>
        <i *ngIf="!template.sendDate" class="icon-email"></i>
        <span *ngIf="template.label" class="label">{{template.label}}</span>
        <span *ngIf="template.name">{{template.name}} - {{template.subject}}</span>
      </div>
      <div class="send-button" *ngIf="mode == 'view'">
        <div *ngIf="template.sendDate" class="send-date">{{template.sendDate}}</div>
        <cm-button *ngIf="!template.sendDate" color="secondary" [disabled]="isSendDisabled"
                   (click)="send(template)">Send</cm-button>
      </div>
      <div class="send-button" *ngIf="mode == 'edit'">
        <div><i class="icon-trash" (click)="remove($event)"></i></div>
      </div>
    </div>
  </div>
</div>
