import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject, throwError, timer } from 'rxjs';
import { RoleEnum } from '@shared/enums/role.enum';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserNameModel } from '@shared/models/user-name.model';
import { GlobalStateService } from '@core/services/global.state.service';
import { ApiClient } from '@core/services/api-client.http.service';
import { AuthUrls, CommonUrls } from '@core/constants';
import { getClientToken } from '@core/helpers/token-parser.helper';
import {
  getCurrentClientId,
  getCurrentResetPasswordAuthority,
} from '@config/msal.config';
import {
  InteractionRequiredAuthError,
  RedirectRequest,
} from '@azure/msal-browser';
import { SiteModel } from '@shared/models';
import { ApplicationsApiService, DashboardApiService } from '../api.services';
import { UserBranchModel } from '@shared/models/user-Branch.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  accountName = '';
  user: UserNameModel = new UserNameModel();
  email = '';
  permissions: number[];
  roles: RoleEnum[];
  isCrm: boolean;
  isAos: boolean;
  isRetailRole: boolean;
  isBusinessRole: boolean;
  isApplicationAvailable: boolean;
  isAosAdmin = false;
  userId: string;
  siteId: string;
  retailRoles = [RoleEnum.PersonalStaff, RoleEnum.PersonalAdmin];
  businessRoles = [RoleEnum.BusinessStaff, RoleEnum.BusinessAdmin];
  IsOfferAdmin = false;
  private newTokenSubject = new Subject<any>();
  public pendoEmail: string;
  public userEmailPendo = new BehaviorSubject('');
  private userBranches: BehaviorSubject<UserBranchModel[]> = new BehaviorSubject<
    UserBranchModel[]
  >([]);

  constructor(
    private msalService: MsalService,
    private globalState: GlobalStateService,
    private api: ApiClient,
    private router: Router,
    private dashboardService: DashboardApiService,
  ) { }

  public login(): void {
    const forgotPassword = localStorage.getItem('forgot_password');
    if (!forgotPassword) {
      this.msalService.loginRedirect();
    } else {
      this.msalService.loginRedirect({
        authority: getCurrentResetPasswordAuthority(),
      } as RedirectRequest);
    }

    localStorage.removeItem('forgot_password');
    localStorage.removeItem('inner_token');
  }

  refreshTokenManually(): void {
    this.refreshInnerLogin(false);
  }

  checkAccountLogin(): boolean {
    return !!this.msalService.instance.getAllAccounts()[0];
  }

  getUserName(): void {
    if (
      this.msalService.instance.getAllAccounts()[0] &&
      this.msalService.instance.getAllAccounts()[0].idTokenClaims
    ) {
      const claims =
        this.msalService.instance.getAllAccounts()[0].idTokenClaims;
      this.user.id = claims.extension_UserId as string;
      this.user.firstName = claims.given_name as string;
      this.user.lastName = claims.family_name as string;
      this.email = claims.emails[0];
      this.siteId = claims.extension_SiteAccess as string;
      this.setRoles();
    }
    this.globalState.userEmail = this.email;
    this.globalState.user = this.user;

    this.globalState.clearBankIntegrationConfiguration();
  }

  setRoles(): void {
    const token = getClientToken();
    this.roles = token.extension_Role.split(',');

    localStorage.setItem('roles', JSON.stringify(this.roles));
    this.isCrm = this.roles.some((item) => item.includes('CRM'));
    this.isAos = this.roles.some((e) => !e.includes('CRM'));
    this.isAosAdmin = this.roles.some((e) => e === 'Admin');

    if (this.roles.some((item) => item.includes('CRM'))) {
      this.IsOfferAdmin = false;
    } else {
      this.IsOfferAdmin = this.roles.some(
        (e) => e.includes('Offer.Admin') || e.includes('Offer.User')
      );
    }
    // TODO: Temporary Application permissions solution. Should be fixed when we have new setup permission strategy
    this.isRetailRole = this.roles.some((r) => this.retailRoles.includes(r));
    this.isBusinessRole = this.roles.some((r) =>
      this.businessRoles.includes(r)
    );
    this.isApplicationAvailable =
      this.roles.some((r) =>
        this.businessRoles.concat(this.retailRoles).includes(r)
      ) || this.isAosAdmin;

    this.userId = token.extension_UserId;
    localStorage.setItem('aos_url', token.extension_SiteUrl);

    this.permissions = token.extension_Permissions;
    if (this.isAosAdmin) {
      this.permissions.push(-1);
    }
  }

  public getInnerToken(): string {
    return localStorage.getItem('inner_token');
  }

  public refreshInnerLogin(reload: boolean): Observable<string> {
    return this.api.put(AuthUrls.login).pipe(
      tap((login: { token: string }) => {
        localStorage.removeItem('inner_token');

        localStorage.setItem('inner_token', login.token);
        this.getUserName();

        if (reload) {
          this.router.navigateByUrl('/', { skipLocationChange: true });
        }
      }),
      map((login: { token: string }) => login.token)
    );
  }

  getAzureToken(): Observable<string> {
    const account = this.msalService.instance.getAllAccounts()[0];

    if (localStorage.getItem('idle') === 'true') {
      localStorage.clear();
      this.msalService.logout();
      return of();
    }

    const accessTokenRequest = {
      account,
      scopes: [getCurrentClientId()],
    };

    return this.msalService.acquireTokenSilent(accessTokenRequest).pipe(
      catchError((err: InteractionRequiredAuthError) => {
        if (
          err.errorMessage.includes('AADB2C90077') ||
          err.errorMessage.includes('AADB2C90080')
        ) {
          localStorage.clear();
          this.msalService.logout();
        }

        return throwError(err);
      }),
      tap(() => this.newTokenSubject.next()),
      map((res) => res.idToken)
    );
  }

  getUserNamePromise(): Promise<UserNameModel> {
    return new Promise((resolve, reject) => {
      if (
        this.msalService.instance.getAllAccounts()[0] &&
        this.msalService.instance.getAllAccounts()[0].idTokenClaims
      ) {
        const claims =
          this.msalService.instance.getAllAccounts()[0].idTokenClaims;
        this.user.id = claims.extension_UserId as string;
        this.user.firstName = claims.given_name as string;
        this.user.lastName = claims.family_name as string;
        this.email = claims.emails[0];
        this.siteId = claims.extension_SiteAccess as string;
        this.setRoles();
        this.globalState.userEmail = this.email;
        this.globalState.user = this.user;
        this.globalState.clearBankIntegrationConfiguration();
        resolve(this.user);
        this.userEmailPendo.next(this.email);
      } else {
        reject('User not found');
      }
    });
  }
  getDefaultLocation(): Observable<SiteModel> {
    return this.dashboardService.getDefaultLocation().pipe(
      map((response => new SiteModel(response)))
    );
  }
  getSiteDetails(): any {
    return this.dashboardService.getSiteDetails();
  }

}
