import { IdentificationDocumentDto } from '@core/services/dto';
import { formatDateSimple } from '@shared/utils';

export class IdentificationDocumentModel {
  id: number;
  expiryDate: string;
  issueDate: string;
  number: string;
  type: {
    id: number,
    name: string;
  };
  documentType: string;
  province: string;
  country: string;

  constructor(dto: IdentificationDocumentDto) {
    this.id = dto.id;
    this.expiryDate = dto.expiryDate ? formatDateSimple(dto.expiryDate) : dto.expiryDate;
    this.issueDate = dto.issueDate ? formatDateSimple(dto.issueDate) : dto.issueDate;
    this.number = dto.number;
    this.type = dto.type;
    this.documentType = dto.type?.name;
    this.province = dto.province.name;
    this.country = 'Canada';
  }
}
